import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Nav from '../components/nav'
import ContactForm from '../components/contactForm'

function SignUp ({ lang }) {
    const texts = {
        es: {
            pageTitle: 'Crear Cuenta',
            pageDescription: 'Creación de cuenta en Optiwe',
            signUp: 'Crear cuenta'
        },
        en: {
            pageTitle: 'Sign-up',
            pageDescription: 'Sign-up in Optiwe',
            signUp: 'New account'
        }
    }

    return (
        <Layout lang={lang}>
            <SEO
                title={texts[lang].pageTitle}
                description={texts[lang].pageDescription}
                lang={lang}
                locales={[
                    {
                        hreflang: 'en',
                        href: 'https://optiwe.com/sign-up/'
                    },
                    {
                        hreflang: 'es',
                        href: 'https://optiwe.com/es/crear-cuenta/'
                    },
                    {
                        hreflang: 'x-default',
                        href: 'https://optiwe.com/sign-up/'
                    }
                ]}
            />

            <main className="container-fluid ow-banner ow-banner--sign-up">
                <Nav lang={lang} />
            </main>
            <section id="form" className="container-fluid ow-section ow-section--form">
                <div className="container ow-contact-form">
                    <svg
                        className="form-shape-left"
                        width="433"
                        height="506"
                        viewBox="0 0 433 506"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M173.471 25.2801C151.952 37.2566 133.247 53.4108 122.712 75.47C114.447 93.0812 111.811 112.874 105.997 131.485C95.3231 165.665 66.814 177.82 42.7513 201.508C13.3311 231.187 -2.24674 274.173 1.42225 315.804C4.6837 352.124 24.559 388.36 62.0974 396.41C79.8453 400.276 98.2295 400.056 115.614 405.375C173.26 423.285 237.059 546.157 307.655 491.481C341.841 465.118 346.528 403.969 375.314 369.115C388.254 353.639 402.737 339.708 414.542 323.369C433.93 296.002 441.017 263.046 417.009 236.387C391.459 208.185 368.723 176.443 355.066 140.8C339.368 100.208 341.83 41.0097 302.888 13.1197C276.655 -5.41316 240.703 -0.524527 210.378 9.63251C197.758 13.8041 185.32 18.7929 173.471 25.2801Z"
                            fill="#CFEEFF"
                        />
                    </svg>
                    <svg
                        width="167"
                        height="97"
                        viewBox="0 0 167 97"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="form-shape-right"
                    >
                        <path
                            d="M65.0883 57.0113C78.4812 51.2343 95.3737 59.6005 107.556 51.9131C116.641 46.2259 118.907 32.4992 113.943 22.9897C108.72 13.5344 98.0807 7.91682 87.0678 6.97531C65.0963 5.35141 -21.49 39.9646 17.0599 73.0097C34.0125 88.1249 51.1774 62.8968 65.0883 57.0113Z"
                            fill="#CFEEFF"
                        />
                        <path
                            d="M158.323 96.3491C159.117 96.0844 160.176 95.8197 160.97 95.2902C164.411 93.4371 166.263 89.4662 166.793 85.7599C167.322 82.0537 165.999 78.0827 164.675 74.3765C163.352 70.935 161.235 66.9641 157.794 65.9052C153.295 64.3168 144.825 66.6993 143.237 71.7292C142.443 74.3765 143.767 77.2885 145.355 79.9359C146.413 81.789 148.001 83.6421 148.531 85.7599C149.06 88.1425 148.266 90.2603 148.531 92.3782C148.795 94.496 150.913 95.555 152.765 96.0844C154.353 96.6139 156.471 96.6139 158.323 96.3491Z"
                            fill="#CFEEFF"
                        />
                    </svg>
                    <h2 className="ow-section__title">{texts[lang].signUp}</h2>
                    <div className="ow-card ow-card--shadow ow-card--form-footer">
                        <ContactForm id="footer" lang={lang} />
                    </div>
                    <svg
                        className="form-shape-bottom"
                        width="439"
                        height="385"
                        viewBox="0 0 439 385"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M41.4606 347.388C20.8166 328.063 5.99523 301.325 1.23122 267.175C-10.9435 181.137 70.0447 179.02 117.42 136.398C144.416 112.043 156.062 75.5098 181.47 49.5662C208.201 22.0343 244.99 4.03261 283.366 0.855847C305.069 -0.997267 328.889 2.70896 344.505 18.0633C360.12 33.6824 363.561 56.9787 369.648 78.1572C383.146 124.75 426.022 155.723 436.873 201.257C442.696 225.347 435.55 251.026 423.905 272.999C413.318 292.854 398.761 310.855 379.705 322.503C348.475 341.564 319.096 328.063 286.013 335.475C255.841 342.358 232.286 366.449 202.643 375.714C143.622 394.245 81.1607 384.45 41.4606 347.388Z"
                            fill="#CFEEFF"
                        />
                    </svg>
                </div>
            </section>
        </Layout>
    )
}

SignUp.propTypes = {
    lang: PropTypes.string.isRequired
}

export default SignUp
